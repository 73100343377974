import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const main = async () => {
    await (await import('./Config')).initAsync();
    const { render } = await import('react-dom');
    const { default: App } = await import('./App');
    await import('./Sentry');

    render(
        <App />,
        document.getElementById('root')
    );
};

main().catch(e => {
    throw e;
});
